import * as echarts from 'echarts';
import React, {useEffect, useRef, useState} from 'react';
import {Box} from '@mui/material';
import moment from 'moment';
import {AssessmentData, SensorData, SimplifiedSensor} from '../types';
import {parseAreas, parseMarkers, simplify, closest} from '../../utils';
import styles from './styles.module.scss';

type EChartsOption = echarts.EChartsOption;

interface Props {
    sensorV2: SimplifiedSensor;
    scale: number;
    select: boolean;
    mark: boolean;
    mean: boolean;
    removing: boolean;
    onAreaRequested: (area: number[]) => void;
    onNewMarker: (markerPos: number) => void;
    onRemove: (pos: number) => void;
    calculateMean: (range: number[]) => string;
}

export function Chart({sensorV2, scale, select, mark, mean, removing, onRemove, onAreaRequested, onNewMarker, calculateMean}: Props) {
    const {name, measures, areas, marks} = sensorV2;
    const chartRef = useRef(null);
    const id = name;

    useEffect(() => {
        const myChart = echarts.getInstanceByDom(chartRef.current!);
        if (!myChart){
            return;
        }

        if (!select) {
            myChart.dispatchAction({
                type: 'takeGlobalCursor',
                key: '' // default behaviour
            });
            myChart.dispatchAction({
                type: 'brush',
                command: 'clear',
                areas: [],
            });
            myChart.off('brushEnd');
        } else {
            myChart.dispatchAction({
                type: 'takeGlobalCursor',
                // If intending to enable brush, must set. Otherwise, the mouse will be disabled to brush.
                key: 'brush',
                brushOption: {
                    brushType: 'lineX',
                    brushMode: 'single'
                }
            })
            myChart.on('brushEnd', (params: any) => { 
                const start = closest(Math.floor(params.areas[0].coordRange[0]),  measures.map(x => [x.timestamp, x.value]));
                const end = closest(Math.floor(params.areas[0].coordRange[1]),  measures.map(x => [x.timestamp, x.value]));
                const currentZoom = ((myChart.getOption() as EChartsOption).dataZoom! as echarts.DataZoomComponentOption[]).find(x => x.type === 'slider') as echarts.SliderDataZoomComponentOption;

                onAreaRequested([start, end]);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const options: EChartsOption = {
                    dataZoom: [
                        currentZoom,
                    ]
                }
                myChart.setOption(options)
            });
        }
    }, [select])

    useEffect(() => {
        const myChart = echarts.getInstanceByDom(chartRef.current!);
        if (!myChart){
            return;
        }

        if (!mean) {
            myChart.dispatchAction({
                type: 'takeGlobalCursor',
                key: '' // default behaviour
            });
            myChart.dispatchAction({
                type: 'brush',
                command: 'clear',
                areas: [],
            });
            myChart.off('brushEnd');
            const options: EChartsOption = {
                tooltip: {
                    show: true,
                    formatter: undefined
                }
            }
            myChart.setOption(options)
        } else {
            myChart.dispatchAction({
                type: 'takeGlobalCursor',
                // If intending to enable brush, must set. Otherwise, the mouse will be disabled to brush.
                key: 'brush',
                brushOption: {
                    brushType: 'lineX',
                    brushMode: 'single'
                }
            });
            const options: EChartsOption = {
                tooltip: {
                    formatter: undefined,
                    show: false,
                }
            }
            myChart.setOption(options)
            myChart.on('brushEnd', (params: any) => { 
                const start = closest(Math.floor(params.areas[0].coordRange[0]),  measures.map(x => [x.timestamp, x.value]));
                const end = closest(Math.floor(params.areas[0].coordRange[1]),  measures.map(x => [x.timestamp, x.value]));
                const result = calculateMean([start, end]);
                const currentZoom = ((myChart.getOption() as EChartsOption).dataZoom! as echarts.DataZoomComponentOption[]).find(x => x.type === 'slider') as echarts.SliderDataZoomComponentOption;

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const options: EChartsOption = {
                    dataZoom: [
                        currentZoom,
                    ],
                    tooltip: {
                        show: true,
                        formatter: 'Mean of selection <br />' + `<b>${result}</b>`
                    }
                }
                myChart.setOption(options)
            });
        }
    }, [mean])

    useEffect(() => {
        const myChart = echarts.getInstanceByDom(chartRef.current!);
        if (!myChart){
            return;
        }

        if (mark) {
            const zr = myChart.getZr();
            zr.on('click', (params: echarts.ElementEvent) => {
                const pointInPixel = [params.offsetX, params.offsetY];
                const pointInGrid = myChart!.convertFromPixel('series', pointInPixel)
                const mouseOverTimestamp = pointInGrid[0];
                const found = closest(Math.floor(mouseOverTimestamp), measures.map(x => [x.timestamp, x.value]));
                const currentZoom = ((myChart.getOption() as EChartsOption).dataZoom! as echarts.DataZoomComponentOption[]).find(x => x.type === 'slider') as echarts.SliderDataZoomComponentOption;

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const options: EChartsOption = {
                    dataZoom: [
                        currentZoom,
                    ],
                }
                myChart.setOption(options);

                onNewMarker(found);         
            })
        } else {
            const zr = myChart.getZr();
            zr.off('click')
        }
        
    }, [mark])

    useEffect(() => {
        const myChart = echarts.getInstanceByDom(chartRef.current!);
        if (!myChart){
            return;
        }

        if (removing) {
            const zr = myChart.getZr();
            zr.on('click', (params: echarts.ElementEvent) => {
                const pointInPixel = [params.offsetX, params.offsetY];
                const pointInGrid = myChart!.convertFromPixel('series', pointInPixel)
                const mouseOverTimestamp = pointInGrid[0];
                const found = closest(Math.floor(mouseOverTimestamp), measures.map(x => [x.timestamp, x.value]));
                onRemove(found);         
            })
        } else {
            const zr = myChart.getZr();
            zr.off('click')
        }
    }, [removing])

    useEffect(() => {

        if (sensorV2 === null || measures.length < 1) {
            return;
        }

        const myChart = echarts.getInstanceByDom(chartRef.current!);
        if (!myChart) {
            return;
        }

        const values = measures.map(x => parseInt(x.value)).map(x => +x.toFixed());
        const min = values.reduce((a, b) => {
            if(isNaN(b)) {
                return a;
            }
            return Math.min(a, b)
        }, +Infinity);
        const max = values.reduce((a, b) => {
            if(isNaN(b)) {
                return a;
            }
            return Math.max(a, b)
        }, -Infinity);

        const option = myChart.getOption() as echarts.EChartsOption;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const options: EChartsOption = {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            series: {
                markLine: {
                    data: marks.map(x => ({ name: x.name ?? '', xAxis: x.value, label: { show: true, formatter: '{b}' }})),
                },
            }
        }
        myChart.setOption(options)

    }, [sensorV2, sensorV2.marks, sensorV2.marks.length])

    useEffect(() => {

        if (sensorV2 === null || measures.length < 1) {
            return;
        }

        const myChart = echarts.getInstanceByDom(chartRef.current!);
        if (!myChart) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const options: EChartsOption = {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            series: {
                markArea: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    data: areas.map(x => [{ name: x.name ?? '', xAxis: x.start }, { xAxis: x.end }]),
                },
            }
        }
        myChart.setOption(options)
    }, [sensorV2, sensorV2.areas, sensorV2.areas.length])

    useEffect(() => {
        if (!chartRef) {
            return;
        }
        if (sensorV2 === null || sensorV2.measures.length < 1) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const options: EChartsOption = {
            dataZoom: [
                {
                    type: 'slider',
                    startValue: moment(measures[0].timestamp).valueOf(),
                    endValue: moment(measures[0].timestamp).add(scale, 'seconds').valueOf()
                },
            ]
        }
        const myChart = echarts.getInstanceByDom(chartRef.current!);
        myChart?.setOption(options);
    }, [scale])

    useEffect(() => {
        if (!chartRef) {
            return;
        }

        if (sensorV2.measures.length === 0) {
            return;
        }
        const values = measures.map(x => parseInt(x.value)).map(x => +x.toFixed());
        const min = values.reduce((a, b) => {
            if(isNaN(b)) {
                return a;
            }
            return Math.min(a, b)
        }, +Infinity);
        const max = values.reduce((a, b) => {
            if(isNaN(b)) {
                return a;
            }
            return Math.max(a, b)
        }, -Infinity);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const option: EChartsOption = {
            tooltip: {
                triggerOn: 'mousemove|click',
            },
            animation: true,
            toolbox: {
                show: false
            },
            brush: {
                toolbox: ['clear'],
                xAxisIndex: 0,
            },
            xAxis: {
                animation: true,
                type: 'time',
                axisLabel : {
                    formatter : function(value: number | string) {
                        const date = moment(value)
                            .subtract(moment(measures[0].timestamp).minutes(), 'minutes')
                            .subtract(moment(measures[0].timestamp).seconds(), 'seconds')
                            .subtract(moment(measures[0].timestamp).milliseconds(), 'milliseconds')
                            .format();
                        return echarts.format.formatTime('mm:ss', date);
                    },
                    margin: 10,
                    color: '#C5C5C8',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 16,
                },
                axisLine: {
                    lineStyle: {
                        color: '#C5C5C8',
                        type: 'solid',
                    },
                    onZero: false,
                },
                axisPointer: {
                    show: true,
                    triggerOn: 'mousemove|click',
                    lineStyle: {
                        type: 'solid',
                        width: 2,
                        color: 'rgba(67, 97, 238, 1)',
                    },
                    label: {
                        show: false
                    },
                    status: 'hide'
                },
                min: measures[0].timestamp,
                axisTick: {
                    show: true
                },
            },
            yAxis: {
                animation: false,
                type: 'value',
                scale: true,
                // min: Math.ceil(min / 10) * 10,
                // max: Math.ceil(max / 10) * 10,
                axisLabel: {
                    formatter: '{value} mV',
                    color: '#C5C5C8',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 16
                },
                
            },
            dataZoom: [
                {
                    type: 'slider',
                    filterMode: 'none',
                    rangeMode: ['value', 'value'],
                    startValue: measures[0].timestamp,
                    endValue: moment(measures[0].timestamp).add(scale, 'seconds').valueOf(),
                    zoomLock: true,
                    brushSelect: false,
                    zoomOnMouseWheel: true,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true,
                    borderColor: '#EAEAEB',
                    fillerColor: 'rgba(67, 97, 238, 0.1)',
                    selectedDataBackground: {
                        lineStyle: {
                            color: 'rgba(67, 97, 238, 1)'
                        }
                    },
                    handleStyle: {
                        color: 'rgba(67, 97, 238, 1)'
                    },
                    borderRadius: 8
                },
                {
                    type: 'inside',
                    zoomLock: true,
                    rangeMode: ['value', 'value'],
                    zoomOnMouseWheel: false,
                    filterMode: 'none',
                }
            ],
            grid: {
                left: '5%',
                right: '5%',
                bottom: '22%'
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            series: {
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                smooth: true,
                smoothMonotone: 'x',
                showSymbol: false,
                data: measures.map(x => [moment(x.timestamp).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), x.value]),
                markArea: {
                    itemStyle: {
                        color: 'rgba(247, 37, 133, 0.16)'
                    },
                    data: areas.map(x => [{ name: x.name ?? '', xAxis: x.start }, { xAxis: x.end }]),
                },
                markLine: {
                    symbol: 'none',
                    animation: false,
                    symbolSize: 0,
                    lineStyle: {
                        color: '#F72585',
                        type: 'solid',
                        width: 2,
                        cap: 'square',
                        join: 'miter',
                    },
                    data: marks.map(x => ({ name: x.name ?? '', xAxis: x.value, label: { show: true, formatter: '{b}' }})),
                },
                silent: true,
                color: 'rgba(67, 97, 238, 1)'
            },
        };

        let myChart = echarts.getInstanceByDom(chartRef.current!);
        if (!myChart) {
            myChart = echarts.init(chartRef.current!, { ssr: true, renderer: 'canvas' });
        }
        myChart.resize({
            height: 320,  
        })
        myChart.setOption(option);
    }, [sensorV2])

    return <Box className={styles.chartWrapper}>
        <h3 className={styles.header}>{id} measure</h3>
        <div id={id} ref={chartRef} style={{ height: '100%', width: '100%' }}></div>
    </Box>
}