import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/shared/page-wrapper';
import {Box, Button, styled, ToggleButtonGroup, Tooltip, useMediaQuery} from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';
import styles from './styles.module.scss';
import IosShareIcon from '@mui/icons-material/IosShare';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SelectAreaIcon from '@mui/icons-material/DataArrayRounded';
import AddMarkerIcon from '@mui/icons-material/AlignVerticalCenter';
import RemoveMarkIcon from '@mui/icons-material/DeleteRounded';
import MeanValueIcon from '@mui/icons-material/JoinInnerRounded';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Patient, Session } from '../../features/patients/types';
import { isLoading } from '../../features/loader';
import JSZip from 'jszip';
import { Chart } from './components/chart';
import { AddNewObjectForm, EditState, Mark, MarkData, SensorData, SimplifiedSensor } from './components/types';
import moment from 'moment';
import { getSessionDownloadLink, updateSessionData } from '../../features/patients';
import CreateNewMarkerModal from './components/create-marker';
import CreateNewAreaModal from './components/create-area';
import { rootMeanSquare, simplify } from './utils';
import DeleteSelectionModal from './components/delete-object';
import TooltipToggleButton from '../../components/shared/controls/tooltip-togglebutton';
import { SVGS } from '../../assets/images.js';
import { locationHistory } from '../../helpers/location-history';
import { match } from 'assert';

const ToggleButton = styled(MuiToggleButton)({
    width: '48px',
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#F72585'
    },
});

const DEFAULT_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const CSV_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]';

const LINES_PATTERN = /"(?<name>[^,]+)","(?<start>[^,]+)","*(?<end>[^,]*)"*,(?<type>[a-z]*)/gm;

const DefaultToggleValue = 30;

export default function PatientDetailsPage() {
    const [toggleValue, setToggleValue] = useState<number>(DefaultToggleValue)
    const [editState, setEditState] = useState<EditState>(EditState.IDLE);

    const dispatch = useAppDispatch();
    const patients = useAppSelector(s => s.patients);
    const [patient, setPatient] = useState<Patient | null>(null);
    const [session, setSession] = useState<Session | null>(null);
    const [previous, setPrevious] = useState<string | null>(null);
    const [next, setNext] = useState<string | null>(null);
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [sensors, setSensors] = useState<SensorData[]>([]);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [simplifiedSensors, setSimplifiedSensors] = useState<SimplifiedSensor[]>([]);
    const [awaitingNewMarker, setAwaitingNewMarker] = useState<{ sensor: number, value: number } | null>(null);
    const [awaitingNewArea, setAwaitingNewArea] = useState<{ sensor: number, start: number, end: number } | null>(null);
    const [awatingObjectRemoving, setAwaitingObjectRemoving] = useState<{ sensor: number, marks: Mark[] } | null>(null);
    const isDesktop = useMediaQuery('(min-width:1000px)');

    const handleChange = (event: React.MouseEvent<HTMLElement>, value: number) => {
        if (value === null) {
            return;
        }
        if (toggleValue === value) {
            return;
        }
        setToggleValue(value);
    }

    const handleCursorChange = (event: React.MouseEvent<HTMLElement>, value: number) => {
        if(value === null) {
            return;
        }
        if (editState === value) {
            return;
        }
        setEditState(value as EditState);
    }

    const downloadFile = async (url: string) => {
        const promises: Promise<void>[] = [];
        let localSensors: Partial<SensorData>[] = [];
        dispatch(isLoading(true))
        fetch(url)
            .then( async (response) => {
                if (response.status === 200 || response.status === 0) {
                    return Promise.resolve(response.blob());
                } else {
                    return Promise.reject(new Error(response.statusText));
                }
            })
            .then(JSZip.loadAsync)
            .then( async (zip) => {
                const fileBase = await zip.generateAsync({ type: 'base64' });
                const cnn = indexedDB.open('nurehab-localdb', 1);
                cnn.onupgradeneeded = (ev) => {
                    const db = cnn.result;
                    if (!db.objectStoreNames.contains('localFiles')) {
                        const store = db.createObjectStore('localFiles', { keyPath: 'id' })
                        store.createIndex('id', 'id', { unique: true });
                    }
                }
                cnn.onsuccess = (ev) => {
                    const db = cnn.result;
                    const tr = db.transaction('localFiles', 'readwrite');
                    const request = tr.objectStore('localFiles').clear()
                    request.onsuccess = (ev) => {
                        if (session) {
                            const query = tr.objectStore('localFiles').add({
                                id: session.id,
                                patient: patient?.id,
                                blob: fileBase
                            });

                            query.onerror = (e) => {
                                console.log(e)
                            }

                            query.onsuccess = (e) => {
                                console.log(e);
                            }
                        }
                    }
                    tr.oncomplete = (ev) => {
                        // nothing
                    }
                }
                cnn.onerror = (ev) => {
                    console.log('global error', cnn.error);
                }
                return zip;
            })
            .then((zip) => {
                const zipFilesDATA = zip.file(/\/data\s/);
                for (const key in zipFilesDATA) {
                    promises.push(zipFilesDATA[key].async('text')
                        .then((text) => {
                            const arr = text.split('\n');
                            const jsonObj = [];
                            const headers = arr[0].split(',');
                            for (let i = 1; i < arr.length; i++) {
                                const data = arr[i].split(',');
                                const obj: any = {};
                                for (let j = 0; j < data.length; j++) {
                                    let objKeyName = headers[j].trim();
                                    if (objKeyName === headers[1]) {
                                        objKeyName = 'value';
                                    }
                                    else if (objKeyName === headers[2]) {
                                        objKeyName = 'blackman';
                                    }
                                    objKeyName = objKeyName.replace('"', '');
                                    objKeyName = objKeyName.replace('"', '');
                                    obj[objKeyName] = data[j].trim();
                                }
                                jsonObj.push(obj);
                            }

                            const sensor: Partial<SensorData> = {
                                name: headers[1].split(' (')[0].replace('"', ''),
                                measures: jsonObj,
                                marks: []
                            }
                            localSensors.push(sensor);
                            return Promise.resolve();
                        }))
                }
                Promise.all(promises).then(() => {
                    const zipFilesMarks = zip.file(/\/marks\s/)
                    for (const key in zipFilesMarks) {
                        promises.push(zipFilesMarks[key].async('text').then((text) => {
                            const name = zipFilesMarks[key].name.match(/(?<=\().*?(?=\))/) ?? '';
                            const arr = text.split('\n')
                            const jsonObj = [];
                            const headers = arr[0].split(',');
                            
                            let body = [...arr];
                            body.shift();
                            if (body.length < 2) {
                                body = body.join('').match(LINES_PATTERN) ?? [];
                            }
                            for (let i = 0; i < body.length; i++) {
                                const data = body[i].split(',');
                                if (data.length < 4) {
                                    continue;
                                }
                                console.log(data, headers);
                                const obj: any = {};
                                for (let j = 0; j < data.length; j++) {
                                    let objKeyName = headers[j].trim();
                                    objKeyName = objKeyName.replace('"', '');
                                    objKeyName = objKeyName.replace('"', '');
                                    obj[objKeyName] = data[j].trim().replace(/['"]+/g, '');
                                }
                                jsonObj.push(obj);
                            }
                            const sensorName = name[0];
                            const sensorLocal = localSensors.find(x => x.name === sensorName);
                            if (sensorLocal) {
                                sensorLocal.marks = jsonObj.filter(x => x['name'] !== '');
                                sensorLocal.marks = sensorLocal.marks.map(x => {
                                    return {
                                        ...x, start: moment(x.start).utc().format(DEFAULT_FORMAT), end: x.end !== undefined && x.end !== '' && moment(x.end, true).utc().isValid()
                                            ? moment(x.end).utc().format(DEFAULT_FORMAT)
                                            : undefined
                                    }
                                })
                                localSensors = [...localSensors.filter(x => x.name !== sensorName), sensorLocal];
                            }
                            return Promise.resolve();
                        }))
                    }
                    Promise.all(promises).then(() => {
                        setSensors(localSensors as SensorData[]);
                        const simplied: SimplifiedSensor[] = [];
                        localSensors.forEach((x, index) => {
                            if (!x.measures) {
                                return
                            }
                            const simple: any[] = simplify(x.measures.map(x => [moment(x.timestamp).valueOf(), +x.blackman]), 0.5, true);
                            simplied.push({
                                name: x.name || '',
                                measures: simple.map(x => ({ timestamp: x[0], value: x[1] })),
                                areas: localSensors[index].marks?.filter(x => x.end || x.type == 'selection').map(x => ({ name: x.name, start: moment(x.start).utc().valueOf(), end: moment(x.end).utc().valueOf() })) ?? [],
                                marks: localSensors[index].marks?.filter(x => !x.end).map(x => ({ name: x.name, value: moment(x.start).utc().valueOf() })) ?? []
                            })
                        });
                        setSimplifiedSensors(simplied);
                    })
                })
            }).catch((error) => {
                console.error('GETTING ZIP ERROR ', error)
            }).finally(() => {
                dispatch(isLoading(false));
            })
    }

    const exportZip = async () => {
        dispatch(getSessionDownloadLink(session!.id)).unwrap().then((result) => {
            const link = document.createElement('a');
            link.download = 'measures';
            link.href = result;
            link.click();
        })
    }

    const getPrevious = () => {
        if (!session || !patient?.sessions) {
            return;
        }
        
        const currentSessionIndex = patient!.sessions!.findIndex(x => x.id === session?.id);
        if (currentSessionIndex === 0) {
            return;
        }
        setPrevious(patient!.sessions![currentSessionIndex - 1].id);
    }

    const getNext = () => {
        if (!session || !patient?.sessions) {
            return;
        }
        const currentSessionIndex = patient!.sessions!.findIndex(x => x.id === session?.id);
        if (currentSessionIndex === patient!.sessions!.length - 1) {
            return;
        }
        setNext(patient!.sessions![currentSessionIndex + 1].id);
    }

    const reloadSession = (sessionId: string) => {
        if (patient !== null) {
            const selectedSession = patient.sessions!.find(x => x.id == sessionId)!;
            setSession(selectedSession);
        }
    }

    const handleChangeSession = (newSessionId: string) => {
        setToggleValue(DefaultToggleValue);
        setPrevious(null);
        setNext(null);
        setSimplifiedSensors([]);
        reloadSession(newSessionId)
    }

    const handleAddNewMarker = (sensorId: number, markerData: number) => {
        setToggleValue(toggleValue)
        
        setAwaitingNewMarker({ sensor: sensorId, value: markerData });
    }

    
    const handleAreaRequested = (sensorId: number, areaValues: number[]) => {
        setToggleValue(toggleValue)

        if (editState === EditState.SELECT) {
            setAwaitingNewArea({ sensor: sensorId, start: areaValues[0], end: areaValues[1] });
        }
    }

    const handleOnObjectRemove = (sensorId: number, position: number) => {
        const sensor = simplifiedSensors[sensorId];

        // if there is any object (area, mark)
        const markDatas: Mark[] = [];
        const areas = sensor.areas.filter(x => position >= x.start && position <= x.end);
        if (areas && areas.length > 0) {
            markDatas.push(...areas.map(x => ({ name: x.name ?? '', start: x.start, end: x.end })))
        }
        const marks = sensor.marks.filter(x => x.value === position)
        if (marks && marks.length > 0) {
            markDatas.push(...marks.map(x => ({ name: x.name ?? '', start: x.value })))
        }

        // try to found mark in click area
        if ((!areas || areas.length < 1) && (!marks || marks.length < 1)){
            const start = position - 100;
            const end = position + 100;
            const clickArea = sensor.marks.filter(x => x.value >= start && x.value <= end + 100);
            if (!clickArea || clickArea.length < 1) {
                return;
            }

            markDatas.push(...clickArea.map(x => ({ name: x.name ?? '', start: x.value })));
        }

        setAwaitingObjectRemoving({ sensor: sensorId, marks: markDatas });
    }

    const handleOnCompleteNewMarker = (result?: AddNewObjectForm) => {
        let finalize = false;
        if (result) {
            const data = awaitingNewMarker!;
            simplifiedSensors[data.sensor].marks.push({ name: result.name, value: data.value })
            sensors[data.sensor].marks.push({ name: result.name, start: moment(data.value).utc().format(DEFAULT_FORMAT) })
            finalize = true;
        }

        resetEdit(finalize);
    }

    const handleOnCompleteNewArea = (result?: AddNewObjectForm) => {
        let finalize = false;
        if (result) {
            const data = awaitingNewArea!;
            simplifiedSensors[data.sensor].areas.push({
                start: data.start,
                end: data.end,
                name: result.name
            })

            sensors[data.sensor].marks.push({ name: result.name, start: moment(data.start).utc().format(DEFAULT_FORMAT), end: moment(data.end).utc().format(DEFAULT_FORMAT), type: 'interval' })
            finalize = true;
        }
        resetEdit(finalize);
    }

    const handleOnCompleteRemoving = (result: boolean) => {
        let finalize = false;
        if (result) {
            const data = awatingObjectRemoving!;
            const indexes: { markIndex: number, viewMark: Mark }[] = [];
            for(const mark of data.marks){
                const index = sensors[data.sensor].marks.findIndex(x => x.name === mark.name && ((mark.end && x.start === moment(mark.start).utc().format(DEFAULT_FORMAT) && x.end === moment(mark.end).utc().format(DEFAULT_FORMAT))
                    || (x.start === moment(mark.start).utc().format(DEFAULT_FORMAT))));
                if (index !== -1) {
                    indexes.push({ markIndex: index, viewMark: mark });
                }
            }

            const indexCandidate = Math.max(...indexes.map(x => x.markIndex));
            const mark = indexes.find(x => x.markIndex === indexCandidate)!.viewMark;
            if (mark.end) {
                const a = simplifiedSensors[data.sensor].areas.findIndex(x => x.start === mark.start && x.end === mark.end);
                simplifiedSensors[data.sensor].areas.splice(a, 1);
            } else {
                const a = simplifiedSensors[data.sensor].marks.findIndex(x => x.value === mark.start && x.name === mark.name);
                simplifiedSensors[data.sensor].marks.splice(a, 1);
            }
            sensors[data.sensor].marks.splice(indexCandidate, 1);
            finalize = true;
        }

        resetEdit(finalize);
    }

    const handleOnCompleteCalculateMeanValue = (result: boolean) => {
        if (result) {
            setEditState(EditState.IDLE);
        }
        resetEdit(false);
    }

    const resetEdit = (finalize: boolean) => {
        if (finalize)
        {
            if (!hasChanges) {
                setHasChanges(true);
            }

            saveDataChanges();
        }

        setAwaitingNewMarker(null);
        setAwaitingNewArea(null);
        setAwaitingObjectRemoving(null);
    }

    const saveDataChanges = () => {
        dispatch(isLoading(true));
        const cnn = indexedDB.open('nurehab-localdb', 1);
        cnn.onsuccess = (ev) => {
            const db = cnn.result;
            const store = db.transaction('localFiles', 'readwrite').objectStore('localFiles');
            const read = store.get(session?.id ?? '')
            read.onsuccess = (ev) => {
                const model = read.result as { id: string | undefined, patient: string | undefined, blob: string | undefined }
                if (model) {
                    Promise.resolve(JSZip.loadAsync(model.blob!, { base64: true })).then(async (zip) => {
                        const zipFilesMarks = zip.file(/\/marks\s/);
                        for (const sensor of sensors) {
                            // eslint-disable-next-line
                            let file = zipFilesMarks.filter(x => x.name.match(/(?<=\().*?(?=\))/)?.[0] ?? '' === sensor.name).find(x => x.name.includes(sensor.name));
                            if (!file && sensor.marks.length > 0) {
                                const csv = createCsv(sensor);
                                zip = pushNewFileToZip(zip, `marks (${sensor.name}).csv`, csv);
                            }
                            if (file && sensor.marks.length > 0) {
                                let csv = await file.async('text');
                                const arr = csvToArray(csv);
                                updateCsv(sensor.marks, arr);
                                csv = rebuildJsonCsv(arr);
                                zip.file(file.name, csv);
                            }
                            if (file && sensor.marks.length < 1) {
                                zip.remove(file.name);
                            }
                        }

                        return zip;
                    })
                        .then((zip) => zip.generateAsync({ type: 'base64' })).then((encode) => { return encode })
                        .then((encode) => {
                            Promise.resolve(() => {
                                const updateModel = {...model}
                                updateModel.blob = encode;
                                store.put(updateModel, session?.id ?? '');
                            })
                            return encode;
                        })
                        .then((encode) => {
                            dispatch(updateSessionData({ patient: patient!.id, session: session!.id, encodedString: encode }))
                        })
                        .then(() => {
                            setHasChanges(false);
                        })
                        .catch((e) => {
                            console.error(e);
                        })
                        .finally(() => {
                            dispatch(isLoading(false))
                        })
                }
            }
            read.onerror = (ev) => {
                dispatch(isLoading(false));
                console.log('unable to save data. session is lost')
            }
        }
    }

    const createCsv = (sensor: SensorData): string => {
        const arr: { name: string, start: string, end: string, type: string }[] = [];
        updateCsv(sensor.marks, arr);
        return rebuildJsonCsv(arr);
    }

    const pushNewFileToZip = (zip: JSZip, fileNameScope: string, data: string): JSZip => {
        let dirName = ''
        for (const name in zip.files) {
            const splitName = name.split('/') // has directory
            if (splitName.length > 1) {
                dirName = splitName[0]
                break;
            }
        }
        zip.file(`${dirName}${dirName.length > 0 ? '/' : ''}${fileNameScope}`, new Blob([data], { type: 'text/csv;charset=utf-8' }));

        return zip;
    }

    const updateCsv = (marks: MarkData[], csv: any[]): string[][] => {
        for (const mark of marks) {
            const index = csv.findIndex((row) => (mark.end && moment(row['start']).utc().format(DEFAULT_FORMAT) === mark.start && moment(row['end']).utc().format(DEFAULT_FORMAT) === mark.end)
                || moment(row['start']).utc().format(DEFAULT_FORMAT) === mark.start)
            if (index === -1) {
                if (mark.end) {
                    csv.push({
                        'name': mark.name,
                        'start': moment(mark.start).utc().format(CSV_FORMAT),
                        'end': moment(mark.end!).utc().format(CSV_FORMAT),
                        'type': 'selection'
                    })
                } else {
                    csv.push({ 'name': mark.name, 'start': moment(mark.start).utc().format(CSV_FORMAT), 'end': '', 'type': 'marker' })
                }
            } else {
                if (mark.name !== csv[index]['name']) {
                    csv[index]['name'] = mark.name;
                }
            }
        }

        csv.forEach((s, index) => {
            if (s['end'] !== '') {
                const found = marks.filter(x => s['end'] !== ''
                    && x.end
                    && x.start === moment(s['start']).utc().format(DEFAULT_FORMAT)
                    && x.end === moment(s['end']).utc().format(DEFAULT_FORMAT));
                if (found.length < 1) {
                    csv.splice(index, 1);
                }
            }
            if (s['end'] === '') {
                const found = marks.filter(x => s['end'] === '' && !x.end && x.start === moment(s['start']).utc().format(DEFAULT_FORMAT));
                if (found.length < 1) {
                    csv.splice(index, 1);
                }
            }
        })

        return csv;
    }

    const csvToArray = (csv: string): Array<any> => {
        const arr = csv.split('\n');
        const jsonObj = [];
        const headers = arr[0].split(',');
        for (let i = 1; i <= arr.length - 1; i++) {
            const data = arr[i].split(',');
            const obj: any = {};
            for (let j = 0; j < data.length; j++) {
                let objKeyName = headers[j].trim();
                objKeyName = objKeyName.replace('"', '');
                objKeyName = objKeyName.replace('"', '');
                obj[objKeyName] = data[j].trim().replace(/['"]+/g, '');
            }
            jsonObj.push(obj);
        }

        return jsonObj;
    }

    const buildNewCsv = (headers: string[]) => {
        return headers.map(x => `"${x}"`).join(',')
    }

    const rebuildJsonCsv = (arr: any[]): string => {
        return [Object.keys(arr[0])].concat(arr).map(x => Object.values(x).map(y => y.length > 0 ? `"${y}"` : '')).join('\n')
    }

    const handleOnBack = () => {
        clearDb();
    }

    const clearDb = () => {
        const cnn = indexedDB.open('nurehab-localdb', 1);

        cnn.onsuccess = (ev) => {
            const db = cnn.result;
            const tr = db.transaction('localFiles', 'readwrite');
            tr.objectStore('localFiles').delete(session?.id ?? '')
        }
    }

    const calculateMean = (sensordId: number, range: number[]): string => {
        const start = range[0];
        const end = range[1];

        const values = simplifiedSensors[sensordId].measures.filter(x => x.timestamp >= start && x.timestamp <= end).map(x => parseFloat(x.value));

        return rootMeanSquare(values).toFixed(3);
    }

    useEffect(() => {
        if (session !== null) {
            dispatch(getSessionDownloadLink(session!.id)).unwrap().then((result) => {
                downloadFile(result);
            });
            getPrevious();
            getNext();
        }
    }, [session])

    useEffect(() => {
        if (patient === null || patient === undefined) {
            return;
        }
        const sessionId = searchParams.get('sessionId')!
        reloadSession(sessionId);
    }, [patient]);

    useEffect(() => {
        if (patients && patients.items) {
            const foundPatient = patients.items!.find(x => x.id == id)!;
            setPatient(foundPatient); 
            return
        }
        console.warn('State empty. Going back to list')
        locationHistory.navigate('/');
    }, [id, patients.total]);

    return <PageWrapper>
        <Box className={styles.header}>
            <Box>
                <div className={styles.back}>
                    <NavLink to='/' onClick={() => handleOnBack()}>
                        <ArrowBackIosIcon sx={{ marginRight: '10px', fontSize: 14 }} />Back to list
                    </NavLink>
                </div>
                {patient && <div className={styles.name}>{patient.name} measures</div>}
            </Box>
            <Box className={styles.optionsWrapper}>
                <div className={styles.options}>
                    <div className={styles.session}>
                        {patient && patient.sessions!.length > 1 && previous
                            ? <NavLink to={`/details/${patient?.id}?sessionId=${previous}`} onClick={() => handleChangeSession(previous)} style={{ height: '20px' }}>
                                <ArrowBackIosIcon sx={{ color: '#8B8C92', fontSize: 14, cursor: 'pointer' }} />
                            </NavLink>
                            : <ArrowBackIosIcon sx={{ color: '#ccc', fontSize: 14 }} />
                        }
                        <div className={styles.selectedSession}>
                            <div>{session?.context}</div>
                            <div className={styles.date}>{session?.startDate}</div>
                        </div>
                        {patient && patient.sessions!.length > 1 && next
                            ? <NavLink to={`/details/${patient?.id}?sessionId=${next}`} onClick={() => handleChangeSession(next)} style={{ height: '20px' }}>
                                <ArrowForwardIosIcon sx={{ color: '#8B8C92', fontSize: 14, cursor: 'pointer' }} />
                            </NavLink>
                            : <ArrowForwardIosIcon sx={{ color: '#ccc', fontSize: 14 }} />

                        }
                    </div>
                    <div className={styles.measures}>
                        <ToggleButtonGroup
                            value={toggleValue}
                            exclusive
                            onChange={handleChange}
                            className={styles.toggle}
                        >
                            <ToggleButton value={15}>15</ToggleButton>
                            <ToggleButton value={30}>30</ToggleButton>
                            <ToggleButton value={60}>60</ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup value={editState} exclusive className={styles.toggle} onChange={handleCursorChange}>
                            <TooltipToggleButton value={EditState.IDLE} TooltipProps={{ title: 'Scroll chart' }}><SVGS.CursorIcon style={{ width: 16, height: 16 }}/></TooltipToggleButton>
                            <TooltipToggleButton value={EditState.SELECT} TooltipProps={{ title: 'Add selection' }}><SelectAreaIcon sx={{ width: 16, height: 16, color: '#6D6F77' }} /></TooltipToggleButton>
                            <TooltipToggleButton value={EditState.MARK} TooltipProps={{ title: 'Add mark' }}><AddMarkerIcon sx={{ width: 16, height: 16, color: '#6D6F77' }} /></TooltipToggleButton>
                            <TooltipToggleButton value={EditState.MEANVALUE} TooltipProps={{ title: 'Calculate mean value of selection' }}><MeanValueIcon sx={{ width: 16, height: 16, color: '#6D6F77' }} /></TooltipToggleButton>
                            <TooltipToggleButton value={EditState.DELETE} TooltipProps={{ title: 'Remove selected' }}><RemoveMarkIcon sx={{ width: 16, height: 16, color: '#6D6F77' }} /></TooltipToggleButton>
                        </ToggleButtonGroup>
                        <Button
                            variant='showMeasures'
                            sx={{ marginLeft: '16px' }}
                            size='large'
                            className={styles.btn}
                            startIcon={<IosShareIcon sx={{ width: 16, height: 16 }} />}
                            onClick={exportZip}
                        >
                            <span className={styles.invisible1000}>Export</span>
                        </Button>
                    </div>
                </div>
            </Box>
        </Box>
        <Box mt={5} paddingX={1} style={{ height: '100%', overflow: 'auto' }}>
            {
                sensors.length === 0 && <div>No Measures data</div>
            }
            {simplifiedSensors && simplifiedSensors.map((sensor, index) => <Chart
                key={sensor.name}
                sensorV2={sensor}
                scale={toggleValue}
                select={editState === EditState.SELECT}
                mean={editState === EditState.MEANVALUE}
                mark={editState === EditState.MARK}
                removing={editState === EditState.DELETE}
                onAreaRequested={(area) => handleAreaRequested(index, area)}
                onNewMarker={(newMarker) => handleAddNewMarker(index, newMarker)}
                onRemove={(position) => handleOnObjectRemove(index, position)}
                calculateMean={(range) => calculateMean(index, range)} />)
            }
            <CreateNewMarkerModal createNewMarker={awaitingNewMarker !== null} onComplete={handleOnCompleteNewMarker} />
            <CreateNewAreaModal createNewArea={awaitingNewArea !== null} onComplete={handleOnCompleteNewArea} />
            <DeleteSelectionModal selection={awatingObjectRemoving !== null} onComplete={handleOnCompleteRemoving} />
        </Box>
    </PageWrapper>
}