export interface SensorData {
    name: string;
    measures: MeasureData[];
    marks: MarkData[];
}

export interface MeasureData {
    value: string;
    timestamp: string;
    blackman: string;
}

export interface AssessmentData {
    mode: string;
    timestamp: string;
    value: string;
    threshold: string;
    max: string;
}

export interface MarkData {
    name: string;
    start: string;
    end?: string;
    type?: string;
}

export interface Measure {
    timestamp: number;
    value: string;
}

export interface AreaSelection {
    name?: string
    start: number;
    end: number;
}

export interface MarkLine {
    value: number;
    name?: string;
}

export interface SimplifiedSensor {
    name: string;
    measures: Measure[];
    marks: MarkLine[];
    areas: AreaSelection[];
}

export interface AddNewObjectForm {
    name: string
}

export interface Mark {
    name: string,
    start: number,
    end?: number
}

export enum EditState {
    IDLE = 0,
    MARK = 1,
    SELECT = 2,
    DELETE = 3,
    MEANVALUE = 4
}